import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { useSpring, animated } from "@react-spring/web";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "../components/ui/navigation-menu";
import { Button } from "../components/ui/button";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto:sanzcodinghelp@gmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/24rr",
  },
];

const Header = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("up");

  // Animation for navbar
  const navAnimation = useSpring({
    transform: scrollDirection === "down" ? "translateY(-100%)" : "translateY(0%)",
    opacity: scrollDirection === "down" ? 0 : 1,
    config: { mass: 1, tension: 180, friction: 12 }
  });

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
      
      if (currentScrollY > lastScrollY && currentScrollY > 80) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <animated.div style={navAnimation}>
      <NavigationMenu className="fixed top-0 left-0 right-0 z-10 bg-black/95 backdrop-blur-sm border-b border-gray-800">
        <NavigationMenuList className="w-full px-6 md:px-10 lg:px-16 py-4 flex justify-between">
          <div className="flex gap-4 md:gap-6">
            {socials.map((social, index) => (
              <NavigationMenuItem key={index}>
                <Button
                  variant="ghost"
                  className="hover:text-cyan-400 transition-colors"
                  asChild
                >
                  <a
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={social.icon} size="xl" />
                  </a>
                </Button>
              </NavigationMenuItem>
            ))}
          </div>

          <div className="flex gap-4 md:gap-6">
            <NavigationMenuItem>
              <Button
                variant="ghost"
                className="hover:text-cyan-400 transition-colors"
                onClick={handleClick("projects")}
              >
                Projects
              </Button>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Button
                variant="ghost"
                className="hover:text-cyan-400 transition-colors"
                onClick={handleClick("skills")}
              >
                Skills
              </Button>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Button
                variant="ghost"
                className="hover:text-cyan-400 transition-colors"
                onClick={handleClick("contact")}
              >
                Contact
              </Button>
            </NavigationMenuItem>
          </div>
        </NavigationMenuList>
      </NavigationMenu>
    </animated.div>
  );
};

export default Header;
