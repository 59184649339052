import React, { useEffect, useState } from "react";
import { Avatar, Heading, VStack, Text } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import { motion } from "framer-motion";
import { TypewriterText, AnimatedSkills } from "./AnimatedText";

const skills = [
  "Roblox Luau",
  "Python",
  "JavaScript",
  "C++",
  "Rust",
  "Node.js",
  "React"
];

const Greeting = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <TypewriterText text="Yo g! I'm " delay={0.5} />
      <motion.span
        initial={{ color: "#ffffff" }}
        animate={{ color: "#61dafb" }}
        transition={{ duration: 1, delay: 1.5 }}
        style={{ fontWeight: "bold" }}
      >
        <TypewriterText text="Bernward" delay={1.2} />
      </motion.span>
      <TypewriterText text=" (" delay={1.8} />
      <motion.span
        className="hoverBorderAnimation"
        initial={{ color: "#ffffff" }}
        animate={{ color: "#ffa500" }}
        transition={{ duration: 1, delay: 2.2 }}
      >
        <TypewriterText text="Bufferization" delay={2} />
      </motion.span>
      <TypewriterText text=")" delay={2.8} />
    </motion.div>
  );
};

const Bio1 = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 3 }}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Text mb={2} color="white">I do script in</Text>
      <AnimatedSkills skills={skills} />
    </motion.div>
  );
};

const LandingSection = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <FullScreenSection
      justifyContent="center"
      alignItems="center"
      backgroundColor="transparent"
      minHeight="100vh"
    >
      <VStack spacing={6} alignItems="center">
        <motion.div
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ 
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 0.2
          }}
        >
          <Avatar
            id="avatar"
            name="Buffer"
            size="2xl"
            src="https://raw.githubusercontent.com/24rr/tmpignore/main/sanz_gun_with_skull_mixed_face_symmetrical_flat_icon_design_f1895bf6-4cce-4db2-bdb8-f7429d4d3443.png"
          />
        </motion.div>
        <Heading
          as="h1"
          size="xl"
          textAlign="center"
          fontFamily="anta"
          fontWeight="600"
          color="white"
        >
          <Greeting />
        </Heading>
        <Heading
          as="h2"
          size="md"
          textAlign="center"
          fontFamily="anta"
          fontWeight="200"
          color="white"
        >
          <Bio1 />
        </Heading>
      </VStack>
    </FullScreenSection>
  );
};

export default LandingSection;