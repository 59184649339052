import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";

export const TypewriterText = ({ text, delay = 0 }) => {
  return (
    <motion.span
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1, delay }}
    >
      {text.split("").map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.2,
            delay: delay + index * 0.05,
            ease: "easeOut"
          }}
        >
          {char}
        </motion.span>
      ))}
    </motion.span>
  );
};

export const AnimatedSkills = ({ skills }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const animateSkills = async () => {
      while (true) {
        // Fade in
        await controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.5 }
        });
        
        // Wait
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        // Fade out
        await controls.start({
          opacity: 0,
          y: 20,
          transition: { duration: 0.5 }
        });
        
        // Change to next skill
        setCurrentIndex(prev => (prev + 1) % skills.length);
        
        // Small pause before next animation
        await new Promise(resolve => setTimeout(resolve, 200));
      }
    };

    animateSkills();
  }, [controls, skills.length]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      style={{ minHeight: "1.5em" }}
    >
      <motion.span
        style={{ 
          color: "#61dafb",
          fontWeight: "bold"
        }}
      >
        {skills[currentIndex]}
      </motion.span>
    </motion.div>
  );
}; 