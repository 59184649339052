import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const ScrollAnimation = ({ children, delay = 0 }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { 
    margin: "-100px",
    amount: 0.3,
    once: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 75 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 75 }}
      transition={{
        duration: 0.4,
        delay: delay,
        ease: [0.17, 0.55, 0.55, 1]
      }}
      style={{
        willChange: 'transform'
      }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimation; 