import React from "react";
import { VStack, Heading } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import Card from "./Card";
import { Progress } from "../components/ui/progress";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPython,
  faNodeJs,
  faCuttlefish,
  faRust,
} from "@fortawesome/free-brands-svg-icons";
import ScrollAnimation from "./ScrollAnimation";

const SkillsSection = () => {
  const skills = [
    { name: "Python", icon: faPython, value: 75, color: "yellow" },
    { name: "C++", icon: faCuttlefish, value: 94, color: "blue" },
    { name: "Rust", icon: faRust, value: 82, color: "orange" },
    { name: "Node.js", icon: faNodeJs, value: 65, color: "green" },
  ];

  const contributionsList = [
    {
      title: "Adonis Roblox",
      description: "Made the Audit Log system + commands.",
      getImageSrc: () => require("../images/Adonis.png"),
      link: "https://github.com/Epix-Incorporated/Adonis",
    },
    {
      title: "Ghost Language (Custom Language)",
      description: "Contributed AST and more syntaxes and functions",
      getImageSrc: () => require("../images/git.jpg"),
    },
    {
      title: "Wave Executor",
      description: "Contributed to the UI syntax",
      getImageSrc: () => require("../images/wave.png"),
    }
  ];

  return (
    <FullScreenSection
      backgroundColor="transparent"
      p={"8%"}
      id="skills-section"
      minHeight={"50vh"}
    >
      <VStack gap={"50px"}>
        <ScrollAnimation>
          <Heading as="h1" fontFamily={"Anta"} fontWeight={400} color="white">
            My Skills
          </Heading>
        </ScrollAnimation>

        <div className="w-full max-w-2xl space-y-8">
          {skills.map((skill, index) => (
            <ScrollAnimation key={skill.name} delay={index * 0.1}>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon 
                    icon={skill.icon} 
                    className={`text-${skill.color}-500 text-2xl`} 
                  />
                  <span className="text-white">{skill.name}</span>
                </div>
                <Progress value={skill.value} className="h-2" />
              </div>
            </ScrollAnimation>
          ))}
        </div>

        <ScrollAnimation delay={0.4}>
          <div className="w-full max-w-3xl">
            <Accordion className="w-full bg-transparent">
              <AccordionItem value="contributions" className="border-none">
                <AccordionTrigger data-value="contributions" className="text-xl font-anta text-white">
                  Contributions
                </AccordionTrigger>
                <AccordionContent data-value="contributions">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {contributionsList.map((contribution, index) => (
                      <ScrollAnimation key={contribution.title} delay={0.5 + (index * 0.1)}>
                        <Card
                          title={contribution.title}
                          description={contribution.description}
                          imageSrc={contribution.getImageSrc()}
                          link={contribution.link}
                        />
                      </ScrollAnimation>
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </ScrollAnimation>
      </VStack>
    </FullScreenSection>
  );
};

export default SkillsSection;