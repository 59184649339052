import React, { useEffect, useState } from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading, VStack } from "@chakra-ui/react";
import Card from "./Card";
import ScrollAnimation from "./ScrollAnimation";

// Static projects
const staticProjects = [
  {
    title: "Discord Bot: Sanz (SHUTDOWNED)",
    description: "An interactive Discord chatbot that can entertain you with a few commands.",
    getImageSrc: () => require("../images/Sanz.png"),
    link: "https://sanz.bufferization.com",
  },
  {
    title: "AzureS (Roblox Colorbot External)",
    description: "A Roblox colorbot external aimbot (PAID)",
    getImageSrc: () => require("../images/azure.png"),
    link: "https://discord.gg/9VJuNutke6"
  },
  {
    title: "CPR-Press",
    description: "A media compressor tool that bypasses any copyright detections",
    getImageSrc: () => require("../images/cpr.png"),
    link: "https://phcorner.net/threads/cpr-press-remove-copyright-infringement-in-a-video-by-compressing-it-2024.2155525/"
  },
  {
    title: "UEF-Mon",
    description: "A lightweight UEFI runtime utility for monitoring, backing up, and recovering critical UEFI variables.",
    getImageSrc: () => require("../images/uefmon.jpg"),
    link: "https://github.com/24rr/uef-mon"
  },
  {
    title: "WriteBlocker X",
    description: "Protect your USB devices from unauthorized modifications and data corruption",
    getImageSrc: () => require("../images/diskwrite.png"),
    link: "https://writeblockerx.us.kg/"
  },
  {
    title: "TrustedInstallerActivator",
    description: "A tool to activate TrustedInstaller on Windows 10 and 11",
    getImageSrc: () => require("../images/windows.png"),
    link: "https://phcorner.net/threads/trustedinstaller-permission-ac-iva-r-do-anything-with-your-pc-only-windows-10-tested.2183004/"
  },
];

const ProjectsSection = () => {
  const [allProjects, setAllProjects] = useState([...staticProjects]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRustyLibraries = async () => {
      try {
        const response = await fetch('https://api.github.com/orgs/rusty-libraries/repos');
        const repos = await response.json();

        // Filter out repos that might already exist in static projects
        const newRepos = repos.filter(repo => 
          !staticProjects.some(staticProject => staticProject.title.toLowerCase() === repo.name.toLowerCase())
        ).map(repo => ({
          title: repo.name,
          description: repo.description || "No description available",
          getImageSrc: () => require("../images/rs.png"), // Default Rust image
          link: repo.html_url
        }));

        setAllProjects([...staticProjects, ...newRepos]);
      } catch (error) {
        console.error("Error fetching repositories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRustyLibraries();
  }, []);

  return (
    <FullScreenSection
      backgroundColor="transparent"
      alignItems="flex-start"
      spacing={8}
      fontFamily="Anta"
      id="projects-section"
      paddingTop="80px"
      paddingX={{ base: 4, md: 8 }}
      paddingBottom={{ base: "32px", md: "80px" }}
      minHeight="100vh"
      position="relative"
    >
      <VStack spacing={8} width="100%" align="stretch">
        <ScrollAnimation>
          <Heading as="h1" fontFamily="anta" fontWeight={400} color="white">
            My Projects
          </Heading>
        </ScrollAnimation>
        <Box
          display="grid"
          gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={{ base: 6, md: 8 }}
          width="100%"
        >
          {loading ? (
            <div className="col-span-3 text-center text-white">Loading projects...</div>
          ) : (
            allProjects.map((project, index) => (
              <ScrollAnimation key={project.title} delay={index * 0.1}>
                <Box
                  width="100%"
                  height="fit-content"
                  marginBottom={{ base: 4, md: 0 }}
                >
                  <Card
                    title={project.title}
                    description={project.description}
                    imageSrc={project.getImageSrc()}
                    link={project.link}
                  />
                </Box>
              </ScrollAnimation>
            ))
          )}
        </Box>
      </VStack>
    </FullScreenSection>
  );
};

export default ProjectsSection;