import React from "react";
import {
  Card as ShadCard,
  CardHeader,
  CardContent,
  CardFooter,
} from "../components/ui/card";
import { HStack, Image } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Card = ({ title, description, imageSrc, link }) => {
  return (
    <div className="transform transition-all duration-300 ease-out hover:-translate-y-2 hover:scale-105">
      <ShadCard className="bg-black border-cyan-500 transition-all duration-300 hover:border-cyan-400 hover:shadow-[0_0_20px_rgba(34,211,238,0.3)] w-full max-w-sm mx-auto">
        <CardHeader className="p-0">
          <div className="overflow-hidden rounded-t-lg">
            <Image
              src={imageSrc}
              alt={title}
              className="w-full h-40 sm:h-48 object-cover transition-transform duration-300 hover:scale-110"
            />
          </div>
        </CardHeader>
        
        <CardContent className="p-3 sm:p-4 space-y-3 sm:space-y-4">
          <h3 className="text-lg sm:text-xl font-anta text-white">{title}</h3>
          <p className="font-karla text-sm sm:text-base text-gray-300">{description}</p>
        </CardContent>

        <CardFooter className="p-3 sm:p-4">
          <HStack spacing={2} className="w-full">
            <FontAwesomeIcon icon={faArrowRight} className="text-white text-sm sm:text-base transition-transform duration-300 group-hover:translate-x-1" />
            <a 
              href={link} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-white hover:text-cyan-400 transition-colors text-sm sm:text-base group"
            >
              More info about this
            </a>
          </HStack>
        </CardFooter>
      </ShadCard>
    </div>
  );
};

export default Card;
