import React, { useRef, useState, useEffect } from "react";
import { VStack, Heading, Input, Textarea, Button, useToast, Text, Box } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import ReCAPTCHA from "react-google-recaptcha";
import ScrollAnimation from "./ScrollAnimation";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser';

const RECAPTCHA_SITE_KEY = "6LdoeKoqAAAAANjIxzfm7lLUiA_DxmtUUtQHhJ5R";
const EMAILJS_PUBLIC_KEY = "fmRKxEbPjhG1I9t1w";
const EMAILJS_SERVICE_ID = "service_rqkgm1s";
const EMAILJS_TEMPLATE_ID = "template_p7wp8e3";

const ContactSection = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    // Initialize EmailJS when component mounts
    emailjs.init(EMAILJS_PUBLIC_KEY);
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!captchaToken) {
      toast({
        title: "Verification required",
        description: "Please complete the reCAPTCHA verification",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Send email using EmailJS
      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        {
          from_email: email,
          from_name: email,
          message: message,
          'g-recaptcha-response': captchaToken,
        }
      );

      if (result.status === 200) {
        toast({
          title: "Message sent!",
          description: "Thanks for reaching out. I'll get back to you soon!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Reset form
        setEmail("");
        setMessage("");
        setCaptchaToken(null);
        recaptchaRef.current.reset();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Form submission error:', error);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FullScreenSection
      backgroundColor="transparent"
      py={20}
      spacing={8}
      width="100%"
      id="contact-section"
      minHeight="100vh"
      justifyContent="center"
    >
      <VStack spacing={12} width="100%" maxW="container.lg" mx="auto" px={4}>
        <ScrollAnimation>
          <VStack spacing={4}>
            <Heading 
              as="h2" 
              size="2xl" 
              color="white" 
              fontFamily="anta"
              className="text-center"
            >
              Get in Touch
            </Heading>
            <Text 
              color="gray.300" 
              fontSize="lg" 
              textAlign="center"
              maxW="2xl"
              className="font-anta"
            >
              Have a question or want to work together? Drop me a message!
            </Text>
          </VStack>
        </ScrollAnimation>

        <ScrollAnimation delay={0.2}>
          <motion.div
            className="w-full backdrop-blur-sm bg-black/10 rounded-xl p-8 border border-cyan-500/20"
            whileHover={{ boxShadow: "0 0 20px rgba(6,182,212,0.2)" }}
            transition={{ duration: 0.15 }}
          >
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <VStack spacing={6} width="100%" align="start">
                <Box width="100%">
                  <Text mb={2} color="white" fontFamily="anta">Email</Text>
                  <Input
                    type="email"
                    placeholder="your@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    size="lg"
                    bg="rgba(0, 0, 0, 0.7)"
                    borderColor="cyan.500/20"
                    _hover={{ borderColor: "cyan.500/50", bg: "rgba(0, 0, 0, 0.8)" }}
                    _focus={{ 
                      borderColor: "cyan.500",
                      bg: "rgba(0, 0, 0, 0.8)",
                      caretColor: "cyan.200"
                    }}
                    color="white"
                    fontFamily="anta"
                    _placeholder={{ color: "gray.400" }}
                  />
                </Box>
                
                <Box width="100%">
                  <Text mb={2} color="white" fontFamily="anta">Message</Text>
                  <Textarea
                    placeholder="Your message here..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    minH="200px"
                    size="lg"
                    bg="rgba(0, 0, 0, 0.7)"
                    borderColor="cyan.500/20"
                    _hover={{ borderColor: "cyan.500/50", bg: "rgba(0, 0, 0, 0.8)" }}
                    _focus={{ 
                      borderColor: "cyan.500",
                      bg: "rgba(0, 0, 0, 0.8)",
                      caretColor: "cyan.200"
                    }}
                    color="white"
                    fontFamily="anta"
                    _placeholder={{ color: "gray.400" }}
                  />
                </Box>

                <div className="flex justify-center w-full py-4">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                    theme="dark"
                  />
                </div>

                <Button
                  type="submit"
                  colorScheme="cyan"
                  width="100%"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Sending..."
                  isDisabled={!captchaToken}
                  fontFamily="anta"
                  fontSize="lg"
                  py={7}
                >
                  Send Message
                </Button>
              </VStack>
            </form>
          </motion.div>
        </ScrollAnimation>
      </VStack>
    </FullScreenSection>
  );
};

export default ContactSection; 