import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const sidebarVariants = {
    open: {
      x: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    closed: {
      x: "-100%",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  const buttonVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { 
      opacity: 1, 
      scale: 1,
      transition: {
        duration: 0.2
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.8,
      transition: {
        duration: 0.1
      }
    }
  };

  const contentVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3
      }
    }
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {!isOpen ? (
          <motion.button
            onClick={() => setIsOpen(true)}
            className="fixed left-4 top-1/2 z-50 -translate-y-1/2 bg-black/20 border border-gray-700 text-white p-3 rounded-full shadow-lg hover:bg-black/40 hover:border-gray-600 transition-all duration-300"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={buttonVariants}
          >
            <FaChevronRight className="w-5 h-5" />
          </motion.button>
        ) : (
          <motion.button
            onClick={() => setIsOpen(false)}
            className="fixed left-80 top-1/2 z-50 -translate-y-1/2 bg-black/20 border border-gray-700 text-white p-3 rounded-full shadow-lg hover:bg-black/40 hover:border-gray-600 transition-all duration-300"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={buttonVariants}
          >
            <FaChevronLeft className="w-5 h-5" />
          </motion.button>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
            className="fixed top-0 left-0 h-full w-80 bg-black/40 backdrop-blur-md border-r border-gray-800 text-white z-40 overflow-y-auto hide-scrollbar"
          >
            <motion.div
              initial="hidden"
              animate="visible"
              variants={contentVariants}
              className="p-8 space-y-8"
            >
              <motion.div variants={itemVariants} className="space-y-6">
                <h2 className="text-3xl font-bold mb-4 font-anta bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">About</h2>
                <p className="text-gray-300 mb-4 leading-relaxed">
                  My name is Sanz, and I work as a professional developer. I am well-versed in these programming languages:
                </p>
                <ul className="list-none space-y-3 pl-4">
                  {[
                    "Python", "C++", "C", "GoLang", "Perl", "Ruby", "Rust",
                    "HTML/CSS (Mark-up language)", "NodeJs/JS", "Julia"
                  ].map((lang) => (
                    <motion.li
                      key={lang}
                      variants={itemVariants}
                      className="text-gray-300 flex items-center space-x-2 hover:text-white transition-colors duration-200"
                    >
                      <span className="w-1.5 h-1.5 bg-blue-500 rounded-full"></span>
                      <span>{lang}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>

              <motion.div variants={itemVariants} className="space-y-6">
                <h2 className="text-3xl font-bold mb-4 font-anta bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">Roles</h2>
                <ul className="list-none space-y-3 pl-4">
                  {[
                    "Backend-developer (Python, NodeJs, & Go)",
                    "Fullstack developer (HTML, CSS)",
                    "Frontend-developer (ReactJS)",
                    "Hobbyist Prompt Engineer"
                  ].map((role) => (
                    <motion.li
                      key={role}
                      variants={itemVariants}
                      className="text-gray-300 flex items-center space-x-2 hover:text-white transition-colors duration-200"
                    >
                      <span className="w-1.5 h-1.5 bg-purple-500 rounded-full"></span>
                      <span>{role}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>

              <motion.div variants={itemVariants} className="space-y-6">
                <h2 className="text-3xl font-bold mb-4 font-anta bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">Former Jobs</h2>
                <ul className="list-none space-y-3 pl-4">
                  {[
                    "Core Developer @ nocaptchaai.com",
                    "Contributor @ Ghost (custom programming language)",
                    "Contributor @ defer.py",
                    "Contributor @ Bagnon (game)",
                    "Developer & Maintainer @ pylightrequests.py",
                    "Developer & Maintainer @ fastaslighttp.go",
                    "Developer & Maintainer @ pyrandomlycr.py",
                    "Developer & Maintainer @ arrange.js",
                    "Developer & Maintainer @ bern.codes",
                    "Developer & Maintainer @ link.bern.codes",
                    "Contributor @ PowerDNS",
                    "Contributor @ Maeel",
                    "Contributor @ github.com/f/awesome-chatgpt-prompts"
                  ].map((job) => (
                    <motion.li
                      key={job}
                      variants={itemVariants}
                      className="text-gray-300 flex items-center space-x-2 hover:text-white transition-colors duration-200"
                    >
                      <span className="w-1.5 h-1.5 bg-blue-500 rounded-full"></span>
                      <span>{job}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>

              <motion.div variants={itemVariants} className="space-y-6">
                <h2 className="text-3xl font-bold mb-4 font-anta bg-gradient-to-r from-blue-500 to-purple-500 bg-clip-text text-transparent">Currently Studying</h2>
                <ul className="list-none space-y-3 pl-4">
                  {[
                    "App Development",
                    "AI Development",
                    "The F# programming language",
                    "The C# programming language",
                    "Basics of the ASM programming language",
                    "Currently concentrating on my school in order to obtain the certification of a Computer Science graduate."
                  ].map((study) => (
                    <motion.li
                      key={study}
                      variants={itemVariants}
                      className="text-gray-300 flex items-center space-x-2 hover:text-white transition-colors duration-200"
                    >
                      <span className="w-1.5 h-1.5 bg-purple-500 rounded-full"></span>
                      <span>{study}</span>
                    </motion.li>
                  ))}
                </ul>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar; 