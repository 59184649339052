import React, { useEffect, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";

const Footer = () => {
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (footerRef.current) {
          footerRef.current.style.opacity = entry.isIntersecting ? "1" : "0";
        }
      },
      {
        threshold: 0.1
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      ref={footerRef}
      as="footer"
      width="100%"
      backgroundColor="black"
      boxShadow="0 -1px 10px rgba(0, 0, 0, 0.8)"
      transition="opacity 0.3s ease-in-out"
      opacity={0}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py={3}
        px={4}
        maxW="7xl"
        mx="auto"
      >
        <Text
          fontSize="sm"
          color="gray.400"
          fontFamily="anta"
        >
          Buffer © {new Date().getFullYear()}
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
